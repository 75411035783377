import { markRaw } from 'vue';

import ActivitySvg from '@/SVG/ActivitySvg.vue';
import AdvertisingSvg from '@/SVG/AdvertisingSvg.vue';
import AlertSvg from '@/SVG/AlertSvg.vue';
import ArrowRightSvg from '@/SVG/ArrowRightSvg.vue';
import AtCircleSvg from '@/SVG/AtCircleSvg.vue';
import BellSvg from '@/SVG/BellSvg.vue';
import BinSvg from '@/SVG/BinSvg.vue';
import BottomCircleSvg from '@/SVG/BottomCircleSvg.vue';
import CalendarSvg from '@/SVG/CalendarSvg.vue';
import ChartSvg from '@/SVG/ChartSvg.vue';
import CheckSvg from '@/SVG/CheckSvg.vue';
import ChevronDownSvg from '@/SVG/ChevronDownSvg.vue';
import ChevronLeftSvg from '@/SVG/ChevronLeftSvg.vue';
import ChevronRightSvg from '@/SVG/ChevronRightSvg.vue';
import ChevronUpSvg from '@/SVG/ChevronUpSvg.vue';
import ClicksSvg from '@/SVG/ClicksSvg.vue';
import CloseSvg from '@/SVG/CloseSvg.vue';
import ConnectionsSvg from '@/SVG/ConnectionsSvg.vue';
import ConversionsSvg from '@/SVG/ConversionsSvg.vue';
import CopySvg from '@/SVG/CopySvg.vue';
import DashboardSvg from '@/SVG/DashboardSvg.vue';
import DatabaseSvg from '@/SVG/DatabaseSvg.vue';
import DollarSvg from '@/SVG/DollarSvg.vue';
import DownloadSvg from '@/SVG/DownloadSvg.vue';
import ErrorSvg from '@/SVG/ErrorSvg.vue';
import EuroSvg from '@/SVG/EuroSvg.vue';
import ExportSvg from '@/SVG/ExportSvg.vue';
import ExportSvg2 from '@/SVG/ExportSvg2.vue';
import FilterSvg from '@/SVG/FilterSvg.vue';
import GlobeSvg from '@/SVG/GlobeSvg.vue';
import HomeSvg from '@/SVG/HomeSvg.vue';
import ImagePlaceholder from '@/SVG/ImagePlaceholder.vue';
import InfoSvg from '@/SVG/InfoSvg.vue';
import InvoiceSvg from '@/SVG/InvoiceSvg.vue';
import ListSvg from '@/SVG/ListSvg.vue';
import LockClosed from '@/SVG/LockClosed.vue';
import LockOpen from '@/SVG/LockOpen.vue';
import MerchantsSvg from '@/SVG/MerchantsSvg.vue';
import MinusSvg from '@/SVG/MinusSvg.vue';
import MoreVertSvg from '@/SVG/MoreVertSvg.vue';
import PenSvg from '@/SVG/PenSvg.vue';
import PerformanceEmpty from '@/SVG/PerformanceEmptySvg.vue';
import PerformanceMapping from '@/SVG/PerformanceMapping.vue';
import PlusSvg from '@/SVG/PlusSvg.vue';
import PriceMonitoringSvg from '@/SVG/PriceMonitoringSvg.vue';
import ProductheroSvg from '@/SVG/ProductheroSvg.vue';
import ProductsEmptySvg from '@/SVG/ProductsEmptySvg.vue';
import ProductsEmptySvg2 from '@/SVG/ProductsEmptySvg2.vue';
import ProductsSvg from '@/SVG/ProductsSvg.vue';
import RefreshSvg from '@/SVG/RefreshSvg.vue';
import RoasReturnSvg from '@/SVG/RoasReturnSvg.vue';
import RoasSpendSvg from '@/SVG/RoasSpendSvg.vue';
import ScrapeGroupsEmptySvg from '@/SVG/ScrapeGroupsEmptySvg.vue';
import ScrapeGroupsEmptySvg2 from '@/SVG/ScrapeGroupsEmptySvg2.vue';
import SearchSvg from '@/SVG/SearchSvg.vue';
import SettingsSvg from '@/SVG/SettingsSvg.vue';
import SettingsSvg2 from '@/SVG/SettingsSvg2.vue';
import ShoppingCartSvg from '@/SVG/ShoppingCartSvg.vue';
import StarSvg from '@/SVG/StarSvg.vue';
import StrategyEmptySvg from '@/SVG/StrategyEmptySvg.vue';
import StrategyEmptySvg2 from '@/SVG/StrategyEmptySvg2.vue';
import SubscriptionSvg from '@/SVG/SubscriptionSvg.vue';
import SuccessSvg from '@/SVG/SuccessSvg.vue';
import TagsSvg from '@/SVG/TagsSvg.vue';
import TagSvg from '@/SVG/TagSvg.vue';
import ToggleOffSvg from '@/SVG/ToggleOffSvg.vue';
import ToggleOnSvg from '@/SVG/ToggleOnSvg.vue';
import TopCircleSvg from '@/SVG/TopCircleSvg.vue';
import TrendDownSvg from '@/SVG/TrendDownSvg.vue';
import TrendUpSvg from '@/SVG/TrendUpSvg.vue';
import UsersSvg from '@/SVG/UsersSvg.vue';
import WarningSvg from '@/SVG/WarningSvg.vue';
import OrangeTarget from '@/SVG/OrangeTarget.vue';
import PurpleCircle from '@/SVG/PurpleCircle.vue';
import BlueCircle from '@/SVG/BlueCircle.vue';
import HeroesSvg from '@/SVG/HeroesSvg.vue';
import SidekicksSvg from '@/SVG/SidekicksSvg.vue';
import VillainsSvg from '@/SVG/VillainsSvg.vue';
import ZombiesSvg from '@/SVG/ZombiesSvg.vue';
import ArrowSvgVue from '@/SVG/ArrowSvg.vue';
import ChevronDownNewSvg from '@/SVG/chevronDownNewSvg.vue';
import CheckedNewSvg from '@/SVG/checkedNewSvg.vue';
import RevertNewSvg from '@/SVG/RevertNewSvg.vue';
import ZombieNoResultsSvg from '@/SVG/ZombieNoResultsSvg.vue';
import ArrowCurrentSvg from '@/SVG/ArrowCurrentSvg.vue';
import LinkNewSvg from '@/SVG/LinkNewSvg.vue';
import SwitchSvg from '@/SVG/SwitchSvg.vue';
import Bars3Svg from '@/SVG/Bars3Svg.vue';
import FilterSvgNew from '@/SVG/FilterSvgNew.vue';
import ExpandAllSvg from '@/SVG/ExpandAllSvg.vue';
import CollapseAllSvg from '@/SVG/CollapseAllSvg.vue';
import MinusNewSvg from '@/SVG/MinusNewSvg.vue';
import LoadingIconNewSvg from '@/SVG/LoadingIconNewSvg.vue';
import MoreHorizontalSvg from '@/SVG/MoreHorizontalSvg.vue';
import ArrowNewSvg from '@/SVG/ArrowNewSvg.vue';
import CloseNewSvg from '@/SVG/CloseNewSvg.vue';
import OkNewSvg from '@/SVG/OkNewSvg.vue';
import SearchNewSvg from '@/SVG/SearchNewSvg.vue';
import BarChartSvg from '@/SVG/BarChartSvg.vue';
import InfoNewSvg from '@/SVG/InfoNewSvg.vue';
import RefreshNewSvg from '@/SVG/RefreshNewSvg.vue';
import ViewModeNewSvg from '@/SVG/ViewModeNewSvg.vue';
import ProductsAiSvgNew from '@/SVG/ProductsAiSvgNew.vue';
import WarningNewSvg from '@/SVG/WarningNewSvg.vue';
import RevertAllNewSvg from '@/SVG/RevertAllNewSvg.vue';
import ImpressionsSvg from '@/SVG/ImpressionsSvg.vue';
import RoasSvg from '@/SVG/RoasSvg.vue';
import DotNewSvg from '@/SVG/DotNewSvg.vue';
import ThumbsDownSvg from '@/SVG/ThumbsDownSvg.vue';
import ThumbsUpSvg from '@/SVG/ThumbsUpSvg.vue';
import ConversionValueSvg from '@/SVG/ConversionValueSvg.vue';
import ProductsNewSvg from '@/SVG/ProductsNewSvg.vue';
import MerchantsNewSvg from '@/SVG/MerchantsNewSvg.vue';
import QuestionRoundedSvg from '@/SVG/QuestionRoundedSvg.vue';
import ChevronRightNewSvg from '@/SVG/ChevronRightNewSvg.vue';
import LabelSvg from '@/SVG/LabelSvg.vue';

export const icons = {
    'activity': markRaw(ActivitySvg),
    'advertising': markRaw(AdvertisingSvg),
    'alert': markRaw(AlertSvg),
    'arrowRight': markRaw(ArrowRightSvg),
    'atCircle': markRaw(AtCircleSvg),
    'bars3': markRaw(Bars3Svg),
    'bell': markRaw(BellSvg),
    'bin': markRaw(BinSvg),
    'blueCircle': markRaw(BlueCircle),
    'bottomCircle': markRaw(BottomCircleSvg),
    'calendar': markRaw(CalendarSvg),
    'chart': markRaw(ChartSvg),
    'check': markRaw(CheckSvg),
    'chevronDown': markRaw(ChevronDownSvg),
    'chevronLeft': markRaw(ChevronLeftSvg),
    'chevronRight': markRaw(ChevronRightSvg),
    'chevronUp': markRaw(ChevronUpSvg),
    'clicks': markRaw(ClicksSvg),
    'close': markRaw(CloseSvg),
    'connections': markRaw(ConnectionsSvg),
    'conversions': markRaw(ConversionsSvg),
    'copy': markRaw(CopySvg),
    'dashboard': markRaw(DashboardSvg),
    'database': markRaw(DatabaseSvg),
    'dollar': markRaw(DollarSvg),
    'download': markRaw(DownloadSvg),
    'error': markRaw(ErrorSvg),
    'euro': markRaw(EuroSvg),
    'export': markRaw(ExportSvg),
    'export2': markRaw(ExportSvg2),
    'filter': markRaw(FilterSvg),
    'globe': markRaw(GlobeSvg),
    'home': markRaw(HomeSvg),
    'imagePlaceholder': markRaw(ImagePlaceholder),
    'info': markRaw(InfoSvg),
    'invoice': markRaw(InvoiceSvg),
    'list': markRaw(ListSvg),
    'lockClosed': markRaw(LockClosed),
    'lockOpen': markRaw(LockOpen),
    'merchants': markRaw(MerchantsSvg),
    'minus': markRaw(MinusSvg),
    'moreVert': markRaw(MoreVertSvg),
    'orangeTarget': markRaw(OrangeTarget),
    'pen': markRaw(PenSvg),
    'performanceEmpty': markRaw(PerformanceEmpty),
    'performanceMapping': markRaw(PerformanceMapping),
    'plus': markRaw(PlusSvg),
    'priceMonitoring': markRaw(PriceMonitoringSvg),
    'producthero': markRaw(ProductheroSvg),
    'productsEmpty': markRaw(ProductsEmptySvg),
    'productsEmpty2': markRaw(ProductsEmptySvg2),
    'products': markRaw(ProductsSvg),
    'purpleCircle': markRaw(PurpleCircle),
    'refresh': markRaw(RefreshSvg),
    'roasReturn': markRaw(RoasReturnSvg),
    'roasSpend': markRaw(RoasSpendSvg),
    'scrapeGroupsEmpty': markRaw(ScrapeGroupsEmptySvg),
    'scrapeGroupsEmpty2': markRaw(ScrapeGroupsEmptySvg2),
    'search': markRaw(SearchSvg),
    'settings': markRaw(SettingsSvg),
    'settings2': markRaw(SettingsSvg2),
    'shoppingCart': markRaw(ShoppingCartSvg),
    'star': markRaw(StarSvg),
    'strategyEmpty': markRaw(StrategyEmptySvg),
    'strategyEmpty2': markRaw(StrategyEmptySvg2),
    'subscription': markRaw(SubscriptionSvg),
    'success': markRaw(SuccessSvg),
    'switch': markRaw(SwitchSvg),
    'tags': markRaw(TagsSvg),
    'tag': markRaw(TagSvg),
    'toggleOff': markRaw(ToggleOffSvg),
    'toggleOn': markRaw(ToggleOnSvg),
    'topCircle': markRaw(TopCircleSvg),
    'trendDown': markRaw(TrendDownSvg),
    'trendUp': markRaw(TrendUpSvg),
    'users': markRaw(UsersSvg),
    'warning': markRaw(WarningSvg),
    'heroes': markRaw(HeroesSvg),
    'sidekicks': markRaw(SidekicksSvg),
    'villains': markRaw(VillainsSvg),
    'zombies': markRaw(ZombiesSvg),
    'arrow': markRaw(ArrowSvgVue),
    'chevronDownNew': markRaw(ChevronDownNewSvg),
    'checkedNew': markRaw(CheckedNewSvg),
    'linkNew': markRaw(LinkNewSvg),
    'revertNew': markRaw(RevertNewSvg),
    'zombieNoResults': markRaw(ZombieNoResultsSvg),
    'filterNew': markRaw(FilterSvgNew),
    'expandAll': markRaw(ExpandAllSvg),
    'collapseAll': markRaw(CollapseAllSvg),
    'minusNew': markRaw(MinusNewSvg),
    'loadingIconNew': markRaw(LoadingIconNewSvg),
    'moreHorizontal': markRaw(MoreHorizontalSvg),
    'arrowNew': markRaw(ArrowNewSvg),
    'closeNew': markRaw(CloseNewSvg),
    'okNew': markRaw(OkNewSvg),
    'searchNew': markRaw(SearchNewSvg),
    'infoNew': markRaw(InfoNewSvg),
    'refreshNew': markRaw(RefreshNewSvg),
    'barChart': markRaw(BarChartSvg),
    'viewModeNew': markRaw(ViewModeNewSvg),
    'productsAiNew': markRaw(ProductsAiSvgNew),
    'warningNew': markRaw(WarningNewSvg),
    'impressions': markRaw(ImpressionsSvg),
    'roas': markRaw(RoasSvg),
    'revertAllNew': markRaw(RevertAllNewSvg),
    'dotNew': markRaw(DotNewSvg),
    'thumbsbUp': markRaw(ThumbsUpSvg),
    'thumbsDown': markRaw(ThumbsDownSvg),
    'conversionValue': markRaw(ConversionValueSvg),
    'productsNew': markRaw(ProductsNewSvg),
    'merchantsNew': markRaw(MerchantsNewSvg),
    'questionRounded': markRaw(QuestionRoundedSvg),
    'chevronRightNew': markRaw(ChevronRightNewSvg),
    'label': markRaw(LabelSvg)
}
